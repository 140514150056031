import React, { HTMLAttributes } from 'react';

import { ReactComponent as IconCalendarSvg } from '@/assets/icon-calendar.svg';
import { ReactComponent as IconMarkerMapsSvg } from '@/assets/icon-marker-maps.svg';
import { ReactComponent as IconStarSvg } from '@/assets/icon-star.svg';

import * as S from './styles';

type Props = HTMLAttributes<HTMLDivElement>;

const SearchBox = (props: Props) => {
  return (
    <S.Wrapper {...props}>
      <S.Inputs>
        <label htmlFor="event">
          <input type="text" id="event" placeholder="Pesquisar Evento" />
          <IconStarSvg />
        </label>

        <label htmlFor="city">
          <input type="text" id="city" placeholder="Pesquisar Cidade" />
          <IconMarkerMapsSvg />
        </label>

        <label htmlFor="date">
          <input type="text" id="date" placeholder="Seleção de Data" />
          <IconCalendarSvg />
        </label>
      </S.Inputs>

      <S.Button>Pesquisar</S.Button>
    </S.Wrapper>
  );
};

export default React.memo(SearchBox);
