import styled, { css } from 'styled-components';

export const FlexContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    margin-bottom: 6rem;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      margin-bottom: 2rem;
      gap: 0.4rem;
      margin-top: 6rem;
    }
  `}
`;

export const HighlightSection = styled.section`
  ${() => css`
    .slick-prev,
    .slick-next {
      z-index: 1;
      width: 4.7rem;
      height: 5.2rem;
    }

    .slick-prev {
      left: -1rem;
      &:before {
        display: none;
      }
    }

    .slick-next {
      right: -1rem;
      &:before {
        display: none;
      }
    }
  `}
`;

export const EventsContainer = styled.div`
  ${() => css`
    width: 90%;
    margin: 0 auto;
    position: relative;
    display: block;

    @media (max-width: 500px) {
      width: 95%;
    }
  `}
`;

export const Section = {
  Wrapper: styled.section`
    margin-bottom: 7.3rem;
  `,

  Header: styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.3rem;
  `,

  Title: styled.h1`
    ${({ theme }) => css`
      color: ${theme.color.primary};
      font-size: 2.1rem;
      font-weight: ${theme.font.weight.medium};
    `}
  `,

  Arrows: styled.div`
    display: flex;
    align-items: center;
  `,

  Arrow: styled.button`
    svg {
      width: 2.1rem;
      height: 2.1rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  `,

  Items: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.8rem;
    grid-row-gap: 1.8rem;

    > * {
      justify-self: center;
      max-width: 30rem;
    }

    @media (max-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  `,

  VerMais: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    font-size: 2rem;
    color: ${({ theme }) => theme.color.primary};

    a:visited {
      color: ${({ theme }) => theme.color.primary};
    }
    a:hover {
      text-decoration: none;
      font-weight: bold;
    }
  `,
};

export const BoxUp = {
  Wrapper: styled.div`
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 10rem;
    margin-top: 2.3rem;
  `,

  Div: styled.div`
    ${({ theme }) => css`
      width: 100%;
      position: relative;
      margin-bottom: 1.7rem;
      background-image: url('/images/background-home-up.jpg');
      background-position: center;
      background-size: cover;

      > div {
        padding: 10rem 2rem;
        p {
          font-weight: ${theme.font.weight.bold};
          color: ${theme.color.white};
          text-align: center;
        }

        p:first-child {
          font-size: 5.4rem;
        }

        p:last-child {
          font-size: 1.6rem;
        }
      }
    `}
  `,
};
