import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    background: ${theme.color.white};
    margin: 4.4rem auto 5rem;
    width: 100%;
    max-width: 66.7rem;

    @media (max-width: 600px) {
      flex-direction: column;
      width: 90%;
    }
  `}
`;

export const Inputs = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.white};
    padding: 0.75rem 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    label {
      border: 1px solid #ededed;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 1rem;

      svg {
        width: 10px;
        margin-left: 0.5rem;
      }

      input {
        width: min(90%, 153px);
        border: 0;
        padding: 0.45rem 0;
        background: transparent;
        font-size: 1.2rem;

        &::placeholder {
          color: ${theme.color.subtext};
        }
      }
    }
    @media (max-width: 600px) {
      flex-direction: column;
      padding: 1.6rem;
      gap: 1.2rem;
      > label {
        width: 100%;
        input {
          width: 100%;
          font-size: 1.6rem;
        }
      }
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    background: ${theme.color.primary};
    padding: 0 3.5rem;
    text-transform: uppercase;
    color: ${theme.color.white};
    font-size: 1.2rem;
    font-weight: ${theme.font.weight.medium};
    @media (max-width: 600px) {
      padding: 1.4rem;
      font-size: 1.6rem;
    }
  `}
`;
