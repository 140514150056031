import styled, { css } from 'styled-components';

export const Wrapper = styled.article`
  ${({ theme }) => css`
    width: 100%;
    position: relative;

    figure {
      padding: 1.6rem;
      background: ${theme.color.white};

      img {
        object-position: top;
        width: 100%;
      }
    }
  `}
`;

export const Header = styled.header`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(-8.5rem);
  margin-bottom: -8.5rem;

  @media (max-width: 500px) {
    transform: translateY(-4rem);
    margin-bottom: -4rem;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.color.primary};
    background: ${theme.color.white};
    font-size: 2.1rem;
    font-weight: ${theme.font.weight.bold};
    padding: 0.5rem 4.5rem;

    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  `}
`;

export const Information = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.white};
    background: ${theme.color.primary};
    padding: 1rem 5rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  `}
`;

export const Date = styled.div`
  ${({ theme }) => css`
    position: relative;
    font-size: 2.1rem;
    font-weight: ${theme.font.weight.regular};
    text-transform: uppercase;
    text-align: center;
    padding-right: 2.2rem;

    span {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 3rem;
      background: ${theme.color.white};
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  `}
`;

export const Location = styled.p`
  ${({ theme }) => css`
    font-size: 2.1rem;
    font-weight: ${theme.font.weight.regular};
    margin-left: 1.2rem;

    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  `}
`;

export const MoreDetails = styled.a`
  ${({ theme }) => css`
    font-size: 1.7rem;
    font-weight: ${theme.font.weight.regular};
    color: ${theme.color.primary};
    text-transform: uppercase;
  `}
`;
