import styled, { css } from 'styled-components';

export const Wrapper = styled.article`
  ${({ theme }) => css`
    background: ${theme.color.white};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lato', sans-serif;
    box-shadow: 6px 8px 12px 2px rgba(0, 0, 0, 0.15);
    width: 30rem;

    @media (max-width: 800px) {
      max-width: 40rem;
    }

    @media (max-width: 600px) {
      max-width: 40rem;
    }

    @media (max-width: 500px) {
      max-width: 35rem;
    }

    @media (max-width: 500px) {
      max-width: 30rem;
    }
  `}
`;

export const Image = styled.img.attrs({
  loading: 'lazy',
})`
  border-radius: 8px;

  object-position: top;
  width: 100%;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: 2.6rem;
    font-weight: 700;
    color: ${theme.color.primary};
    margin-top: 1.4rem;
    max-width: 90%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const EventInfo = styled.div`
  ${({ theme }) => css`
    padding: 1.2rem 0 0.6rem;
    width: calc(100% - 2.6rem);
    margin: 0.8rem auto;
    border-top: 2px solid ${theme.color.background};
    text-align: center;
  `}
`;

export const Date = styled.p`
  ${({ theme }) => css`
    font-size: 2rem;
    color: ${theme.color.primary};
    margin-bottom: 0.4rem;
  `}
`;

export const Location = styled.p`
  ${({ theme }) => css`
    font-size: 1.6rem;
    color: ${theme.color.primary};
    font-weight: ${theme.font.weight.light};
  `}
`;

export const Details = styled.a`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.primary};
    border-radius: 8px;
    padding: 0.4rem 0.8rem;
    margin-bottom: 2rem;
    color: ${theme.color.primary};
    background-color: ${theme.color.white};
    transition: 0.3s;

    :hover {
      text-decoration: none;
      background-color: ${theme.color.primary};
      color: ${theme.color.white};
    }
  `}
`;
