/* eslint-disable @typescript-eslint/no-unused-vars */
import Link from 'next/link';
import React, { useMemo } from 'react';
import SlickSlider, { CustomArrowProps } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IEvent } from '@/@types/events';
import { ReactComponent as IconArrowBgRedLeftSvg } from '@/assets/arrow-bg-red-left.svg';
import { ReactComponent as IconArrowBgRedRightSvg } from '@/assets/arrow-bg-red-right.svg';
import EventBox from '@/components/EventBox';
import Layout from '@/components/Layout';
import SearchBox from '@/components/SearchBox';
import { Container, TitleHidden } from '@/styles';
import BoxHighlight from '@/templates/home/box-highlight';
import { makeCdnLink } from '@/utils';

import * as S from './styles';

export type TemplateHomeProps = {
  events: IEvent[];
  featuredEvents: IEvent[];
  isAuthModalOpen: boolean;
};

const TemplateHome = ({
  events,
  featuredEvents,
  isAuthModalOpen,
}: TemplateHomeProps) => {
  const NextArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => (
    <div {...props}>
      <IconArrowBgRedRightSvg />
    </div>
  );
  const PrevArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => (
    <div {...props}>
      <IconArrowBgRedLeftSvg />
    </div>
  );

  function getCityAndState(location: string) {
    const semiParsedLocation = location.split('-');

    semiParsedLocation[0] = `${semiParsedLocation[0].split(',')[0]} `;

    const parsedLocation = semiParsedLocation.join('-').trim();
    return parsedLocation;
  }

  return (
    <Layout isHomePage isAuthModalOpen={isAuthModalOpen}>
      <S.FlexContainer>
        <SearchBox />
        <Container>
          <S.HighlightSection>
            <TitleHidden>Destaques</TitleHidden>
            <SlickSlider
              prevArrow={<PrevArrow />}
              nextArrow={<NextArrow />}
              slidesToShow={1}
              autoplay
              autoplaySpeed={5000}
            >
              {featuredEvents.map(row => (
                <BoxHighlight
                  key={`home-highlight-${row.codEvento}`}
                  title={row.nomeEvento}
                  location={row.descLocal}
                  city={`${row.nomeCidade} - ${row.siglaEstado}`}
                  createdAt={row.dataIniEvento}
                  redirectTo={`/evento/${row.codEvento}/${row.slugEvento}`}
                  imageUrl={makeCdnLink(row.imagemEvento)}
                />
              ))}
            </SlickSlider>
          </S.HighlightSection>
        </Container>
      </S.FlexContainer>

      <S.EventsContainer>
        <S.Section.Wrapper>
          <S.Section.Header>
            <S.Section.Title>Eventos</S.Section.Title>
          </S.Section.Header>
          <S.Section.Items>
            {events.map(row => (
              <EventBox
                key={`home-featured-events-${row.codEvento}`}
                title={row.nomeEvento}
                createdAt={row.dataIniEvento}
                imageUrl={makeCdnLink(row.imagemEvento)}
                location={getCityAndState(row.localEvento)}
                redirectTo={`/evento/${row.codEvento}/${row.slugEvento}`}
              />
            ))}
          </S.Section.Items>

          <S.Section.VerMais>
            <a href="/eventos">Ver todos os eventos</a>
          </S.Section.VerMais>
        </S.Section.Wrapper>
      </S.EventsContainer>

      <S.BoxUp.Wrapper>
        <S.BoxUp.Div>
          <div>
            <p>Dê UP nos seus eventos!</p>
            <p>Entre em contato conosco e conheça nossas soluções de gestão</p>
          </div>
        </S.BoxUp.Div>
      </S.BoxUp.Wrapper>
    </Layout>
  );
};

export default React.memo(TemplateHome);
