import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';

import { ReactComponent as IconMarkerMapsSvg } from '@/assets/icon-marker-maps.svg';

import * as S from './styles';

type Props = {
  title: string;
  location: string;
  imageUrl: string;
  createdAt: string;
  redirectTo: string;
};

const EventBox = ({
  title,
  location,
  imageUrl,
  createdAt,
  redirectTo,
}: Props) => {
  const parseDate = useMemo(
    () => new Date(createdAt.replace(/-/g, '/')),
    [createdAt],
  );
  const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const padStart = useCallback(
    (value: number) => value.toString().padStart(2, '0'),
    [],
  );
  return (
    <S.Wrapper>
      {imageUrl && (
        <a href={redirectTo}>
          <S.Image src={imageUrl} alt={title} />
        </a>
      )}

      <S.Title>{title}</S.Title>

      <S.EventInfo>
        <S.Date>
          {`${weekDays[parseDate.getDay()]} - ${padStart(parseDate.getDate())}`}{' '}
          de {months[parseDate.getMonth()]} - {parseDate.getHours()}:
          {padStart(parseDate.getMinutes())}
        </S.Date>
        <S.Location>
          <span>{location}</span>
        </S.Location>
      </S.EventInfo>

      <S.Details href={redirectTo}>Ver Detalhes</S.Details>
    </S.Wrapper>
  );
};

export default React.memo(EventBox);
