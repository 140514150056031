import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';

import { getDayName } from '@/utils';

import * as S from './styles';

type Props = {
  title: string;
  location: string;
  city: string;
  imageUrl: string;
  createdAt: string;
  redirectTo: string;
};

const BoxHighlight = ({
  title,
  location,
  city,
  imageUrl,
  createdAt,
  redirectTo,
}: Props) => {
  const parseDate = useMemo(
    () => new Date(createdAt.replace(/\s+/g, 'T').concat('.000-03:00')),
    [createdAt],
  );
  const padStart = useCallback(
    (value: number) => value.toString().padStart(2, '0'),
    [],
  );

  return (
    <S.Wrapper>
      <figure>
        <a href={redirectTo}>
          <img src={imageUrl} alt={title} loading="lazy" />
        </a>
      </figure>

      <S.Header>
        <S.Title>{title}</S.Title>

        <S.Information>
          <S.Date>
            {parseDate && (
              <>
                <span>
                  {getDayName(parseDate.getDay()).slice(0, 3).toUpperCase()}
                </span>
                <span>
                  {padStart(parseDate.getDate())}/
                  {padStart(parseDate.getMonth() + 1)}
                </span>
              </>
            )}
          </S.Date>

          <S.Location>
            <span>{location}</span>
            <br />
            <span>{city}</span>
          </S.Location>
        </S.Information>

        <S.MoreDetails href={redirectTo}>+ Detalhes</S.MoreDetails>
      </S.Header>
    </S.Wrapper>
  );
};

export default React.memo(BoxHighlight);
