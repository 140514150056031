import { GetServerSideProps, NextPage } from 'next';
import React from 'react';

import { api } from '@/services/api';
import TemplateHome, { TemplateHomeProps } from '@/templates/home';

const Home: NextPage<TemplateHomeProps> = props => (
  <>
    <TemplateHome {...props} />
  </>
);

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  const response = await api.get('/v1/web/home');

  let authModalOpen = false;
  if (query.AuthModalOpen) {
    authModalOpen = true;
  }

  return {
    props: {
      isAuthModalOpen: authModalOpen,
      events: response.data.events,
      featuredEvents: response.data.featuredEvents,
    },
  };
};

export default Home;
